export const Business = Object.freeze({
    name: "Strange Works",
    address: "275 NW Victoria Dr",
    city: "Lees Summit",
    state: "MO",
    zip: "64086",
    phoneNumber: "(816) 434-7001",
    email: "info@strangeworks.com",
    url: "https://www.strangeworks.com",
    facebook: "https://www.facebook.com/Strange-Works-246869319312612/",
    twitter: "https://twitter.com/Strange_Works_",
    instagram: "https://www.instagram.com/strange_works_/",
    youtube: "https://www.youtube.com/channel/UCFKao1I21D94jpfuSajn_dg"
})